.App {
  width: 100%;
  position: relative;
}

html{
  scroll-behavior: smooth;
}

.background-image{
  background-image: url("./assets/top-background.jpg");
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}

.fab{
  font-size: 5rem;
  color: #fafafa;
  transition: .25s;
  @media screen and (max-width: 1350px){
    font-size: 3.5rem
  }
  &:hover{
    color: #fafafaab
  }
}

.fa-times-circle {
  position: absolute;
  color: #fafafa;
  top: 10px;
  right: 10px;
  font-size: 3.6rem;
  opacity: .5;
  cursor: pointer;
  transition: .25s;
  &:hover {
    opacity: 1;
    color: #fafafa;
  }
}
