.pageContainer{
    position: relative;
    margin-bottom: -100px;
    .colorOverlay{
        width: 100%;
        position: absolute;
        height: 100%;
        background: #410b0b;  
        position: absolute;   
        opacity: .6; 
        z-index: 0;
        box-shadow: 0 0 100px 125px #410b0b
    }
    .titleText{
        margin-left: 8%;
        top: 80px;
        position: relative;
        color: #fafafa;
        font-size: 2.7rem;
        z-index: 1;
        @media screen and (max-width: 600px) {
            font-size: 2rem;
            text-align: center;
            margin-left: 0;
        }
    }
    .actionText{
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 160px;
        @media screen and (max-width: 600px) {
            top: 120px;
        }
        .questionText{
            font-size: 1.9rem;
            color: #fafafa;
            @media screen and (max-width: 600px) {
                font-size: 1.5rem;
            }
        }
        .instructionText{
            margin-top: 25px;
            font-size: 1.5rem;
            color: #fafafa;
            @media screen and (max-width: 600px) {
                font-size: 1.2rem;
            }
        }
    }
}