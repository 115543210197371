.formContainer{
    position:relative;
    margin: 200px auto;
    width: 35%;
    min-width: 500px;
    @media screen and (max-width: 600px) {
        min-width: 300px;
    }
    .form{
        display:flex;
        flex-direction: column;
        background: #435072;
        padding: 10px;
        .inputField{
            color: #d2ccdd;
            outline: 0;
            border: 0;
            border-bottom: 2px solid #2d3549;
            font-size: 1.45rem;
            padding: 5px;
            background: #00000000;
            @media screen and (max-width: 600px) {
                font-size: 1.1rem
            }
        }
        .inputField::placeholder{
            color: #8585a5
        }
        .largeInput{
            outline: 0;
            border: 0;
            font-size: 1.3rem;
            padding: 5px;
            height: 250px;
            background: #00000000;
            max-width: 100%;
            color: #d2ccdd;
            @media screen and (max-width: 600px) {
                font-size: 1rem;
            }
        }
        .largeInput::placeholder{
            color: #8585a5
        }
        .errors{
            color: #db9b9b;
            font-size: 1.2rem;
            @media screen and (max-width: 600px) {
                font-size: 1.1rem;
            }
        }
        .sendButton{
            background: #00000000;
            border: 2px solid #d1cdcd;
            color: #d1cdcd;
            font-size: 1.55rem;
            width: 30%;
            cursor: pointer;
            transition: .25s;
            &:hover{
                background: #d1cdcd;
                color: #435072
            }
        }
    }
}