.pageContainer{
    color: #fafafa;
    display: flex;
    position: relative;
    @media screen and (max-width: 1000px){
        flex-direction: column;
        text-align: center;
    }
    .colorOverlay{
        width: 100%;
        height: 100%;
        background: #182035;  
        position: absolute;   
        opacity: .6; 
        z-index: 0;
        box-shadow: 0 0 100px 100px #182035
    }
    .mainTextContainer{
        position: relative;
        z-index: 1;
        height: 100%;
        display: flex;
        flex-direction:column;
        justify-content: center;
        margin-left: 8%;
        margin: 200px 8%;
        @media screen and (max-width: 1000px){
            margin: 130px 0 0;
        }
        .headerText{
            font-size: 5rem;
            @media screen and (max-width: 1350px){
                font-size: 3rem;
            }
        }
        .headerSubText{
            margin-top: 22px;
            margin-left: 120px;
            font-size: 2.6rem;
            @media screen and (max-width: 1350px){
                font-size: 1.8rem;
                margin-left: 80px;
            }
            @media screen and (max-width: 1000px){
                margin-left: 0
            }
        }
        .aboutButton{
            transition: .2s;
            font-size: 2rem;
            margin: 38px 120px;
            color: #fafafa;
            border: solid 2px #fafafa;
            border-radius: 50px;
            width: 250px;
            padding: 10px 30px;
            background: #ffffff00;
            cursor: pointer;
            &:hover{
                border: solid 2px #00000000;
                background: #fafafa8b;
                color: #182035;
                transition: .2s;
            }
            @media screen and (max-width: 1350px){
                font-size: 1.5rem;
                margin-left: 80px;
            }
            @media screen and (max-width: 1000px){
                font-size: 1.5rem;
                margin: 38px auto;
            }
        }
        .iconContainer{
            display: flex;
            margin-left: 120px;
            width: 200px;
            justify-content: space-between;
            @media screen and (max-width: 1350px){
                margin: 0 auto;
                width: 120px;
            }
        }
    }
}