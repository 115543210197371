.pageContainer{
    position: relative;
    .colorOverlay{
        position: absolute;
        width: 100%;
        height: 100%;
        background: #182035;  
        position: absolute;   
        opacity: .6; 
        z-index: 0;
        box-shadow: 0 0 100px 100px #182035
    }
    .titleText{
        text-align: right;
        margin-right: 8%;
        top: 80px;
        position: relative;
        color: #fafafa;
        font-size: 2.7rem;
        z-index: 1;
        @media screen and (max-width: 600px) {
            font-size: 2rem;
            text-align: center;
            margin-right: 0px;
        }
    }
    .cardsContainer{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 75%;
        border-radius: 15px;
        margin: 185px auto;
        @media screen and (max-width: 600px) {
            margin: 125px auto;
        }
    }
}