.container {
    position: absolute;
    top: 0;
    right: 0;
    padding: 25px 25px 0 0;
    .bars{
        position: relative;
        cursor: pointer;
        z-index: 4;
        .bar {
            width: 30px;
            height: 5px;
            background: #fafafa;
            margin-top: 5px;
            border-radius: 10px;
        }
    }
}