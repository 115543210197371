.colorOverlay{
    top: 0;
    position: fixed;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    background: #19193d7e;
}

.modalContainer{
    position: fixed;
    display: flex;
    text-align: center;
    top: 0;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    .modal{
        top: 0;
        height: 20%;
        width: 30%;
        padding: 25px 0;
        background: #e9dcdc;
        margin: 20% auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .message{
            font-size: 1.45rem;
            margin: 0 auto;
            background: #faf8f8;
            width: 100%
        }
        .confirmButton{
            padding: 5px;
            width: 50%;
            margin: 0 auto;
            background: #7574b8;
            border: none;
            font-size: 1.3rem;
            color: #fafafa;
            cursor: pointer;
            box-shadow: 2px 2px 3px#000000;
            &:active{
                box-shadow: none;
                border: none;
                outline: none;
                transform: translateY(3px);
            }
        }
    }
}
