.aboutContainer{
    position: relative;
    font-size: 1.35rem;
    width: 50%;
    line-height: 1.5;
    margin: 200px auto;
    margin-right: 50px;
    transition: .25s;
    @media screen and (max-width: 1350px) {
        width: 80%;
        margin: 220px auto;
        font-size: 1.15rem;
    }
    @media screen and (max-width: 1000px){
        margin: 30px auto 200px;
    }
    .backButton{
        transition: .2s;
            font-size: 2rem;
            margin: 38px 0;
            color: #fafafa;
            border: solid 2px #fafafa;
            border-radius: 50px;
            width: 250px;
            padding: 10px 30px;
            background: #ffffff00;
            cursor: pointer;
            &:hover{
                border: solid 2px #00000000;
                background: #fafafa8b;
                color: #182035;
                transition: .2s;
            }
    }
}

.fadeInToggle {
    opacity: 1
}

.fadeOutToggle {
    opacity: 0;
    transform: translateY(25%);
    @media screen and (max-width: 1000px){
        height: 0px;
    }  
}
