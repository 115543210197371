.skillsSection{
    position: relative;
    .colorOverlay{
        width: 100%;
        height: 100%;
        background: #410b0b;  
        position: absolute;   
        opacity: .6; 
        z-index: 0;
        box-shadow: 0 0 100px 125px #410b0b
    }
    .headerText{
        margin-left: 8%;
        top: 80px;
        position: relative;
        color: #fafafa;
        font-size: 2.7rem;
        z-index: 1;
        @media screen and (max-width: 600px) {
            margin-left: 0px;
            text-align: center;
            font-size: 2rem;
        }
    }
    .cardsContainer{
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        z-index: 1;
        height: 60%;
        width: 75%;
        border-radius: 15px;
        margin: 185px auto;
        @media screen and (max-width: 1400px) {
            width: 95%;
            margin: 125px auto;
        }
    }
}

