.navContainer{
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 100%;
    z-index: 3;
    .logo{
        margin: 25px 50px;
    }
    .navButtons{
        width: 35%;
        margin: 35px 45px;
        display: flex;
        justify-content: space-evenly;
        @media screen and (max-width: 1000px) {
            margin-right: 150px;
        }
        @media screen and (max-width: 750px) {
            margin-right: 250px;
        }
        .linkItem{
            color: #fafafa;
            text-decoration: none;
            font-size: 2.3rem;
            margin: 0 13px;
            @media screen and (max-width: 1000px) {
                font-size: 1.8rem;
            }
        }
    }
}