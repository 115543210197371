.colorOverlay{
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #19193d7e;
}

.modal{
    overflow: hidden;
    border-radius: 10px;
    position: fixed;
    top: 0;
    margin: 100px auto;
    overflow: auto;
    z-index: 2;
    width: 30%;
    min-width: 500px;
    height: 80%;
    background: #e9dcdc;
    box-shadow: 0 0 10px 3px #242323;
    @media screen and (max-width: 550px) {
        min-width: 350px;
    }
    .imgContainer{
        width: 100%;
        img{
            width: 100%
        }
    }
    .nameContainer{
        text-align: center;
        margin-top: -10px;
        .nameText{
            background: #7574b8;
            color: #fafafa;
            font-size: 1.5rem;
            padding: 5px;
            box-shadow: 5px 3px 10px #3a3636;
            @media screen and (max-width: 550px) {
                font-size: 1.1rem;
            }
        }
    }
    .links{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 35px;
        .projectLink{
            font-size: 1.3rem;
            text-decoration: none;
            @media screen and (max-width: 550px) {
                font-size: 1.1rem;
            }
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .descriptionContainer{
        margin: 35px 25px;
        font-size: 1.1rem;
        line-height: 1.6rem;
        @media screen and (max-width: 550px) {
            font-size: 1rem;
        }
    }
    .techContainer{
        width: 100%;
        .techTitle{
            text-align: center;
            font-size: 1.3rem;
            margin-bottom: 20px;
            padding: 4px;
            background: #fffefe;
        }
        .listContainer{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: 120px;
            margin: 0 20px;
            
            li{
                margin-bottom: 10px;
                font-size: 1.2rem;
                @media screen and (max-width: 550px) {
                    font-size: 1rem;
                }
            }
        }
    }
}