.container{
    width: 300px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #1b1731;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 25px;
    transition: .25s;
    box-shadow: 0 0 8px 5px #00000057;
    span{
        margin: 23px 0;
    }
    .linkItem{
        text-decoration: none;
        color: #fafafa;
        font-size: 1.5rem;
        cursor: pointer
    }
}

.showNav{
    opacity: 1;
}

.hideNav{
    opacity: 0;
    transform: translateY(-150px);
}