.card{
    width: 500px;
    height: 300px;
    margin-bottom: 30px;
    background: #4350729a;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: .25s;
    cursor: pointer;
    @media screen and (max-width: 460px) {
        height: 320px;
    }
    .imageContainer{
        position: relative;
        overflow: hidden;
        height: 250px;
        .colorOverlay{
            width: 100%;
            height: 100%;
            position: absolute;
            background: #5e627963;
            z-index: 1;
            transition: .25s;
        }
        .pic{
            width: 100%;
            height: 100%;
            transition: .25s;
        }
    }
    
    .titleContainer{
        text-align: center;
        margin-top: 10px;
        .titleText{
            color: #fafafa;
            font-size: 1.5rem;
            @media screen and (max-width: 600px) {
                font-size: 1.3rem;
            }
            
        }
    }
    &:hover {
        background: #435072;
        .colorOverlay{
            width: 100%;
            height: 100%;
            position: absolute;
            background: #00000000;
            z-index: 2;
            transition: .25s;
        }
        .pic{
            width: 105%;
            height: 105%;
            margin-left: -2.5%;
            margin-top: -1.5%;
        }
    }
}
