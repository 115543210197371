.card{
    width: 30%;
    height: 100%;
    min-height: 500px;
    background: #2d3549ea;
    border-radius: 12px;
    min-width: 400px;
    margin: 0 auto 45px;
    @media screen and (max-width: 600px){
        min-width: 300px;
    }
    .titleContainer{
        width: 100%;
        margin-top: 15px;
        text-align: center;
        .cardTitle{
            color: #beaeae;
            font-size: 2.2rem; 
            font-weight: bold;
            @media screen and (max-width: 1400px) {
                font-size: 1.7rem;
            }
        }
    }
    hr{
        background: #090B0C;
        color: #090B0C;
        border-color: #090B0C;;
    }
    .skillContainer{
        text-align: center;
        display: flex;
        flex-direction: column;
        .skillText{
            font-size: 1.8rem;
            margin-top: 15px;
            color: #e7dfdf;
            @media screen and (max-width: 1400px) {
                font-size: 1.5rem
            }
        }
    }
}
